* { box-sizing: border-box; }
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');


body {
  font-family: 'Rubik', sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  height: 90vh;
  padding: 2rem;
  width: 80%;
}

.left {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  animation-name: left;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  margin-left: 5rem;
}

.right {
  flex: 1;
  background-color: rgba(0, 0, 0, 0.366);
  transition: 1s;
  height: 100%;
  background-size: cover;
  filter: grayscale(0%);
  padding: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 750px) {

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    padding: 2rem;
  }
  .right {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.366);
    transition: 1s;
    height: 100%;
    width: 100% !important;
    background-size: cover;
    filter: grayscale(0%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .left {
    margin-left: 0rem;
    width: 100% !important;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .header > h2 {
    margin: 0;
    font-size: 1rem;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
  }
  
  .header > h4 {
    margin-top: 10px;
    font-size: .5rem !important;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 10px;
    color: rgba(213, 213, 213, 0.768);
  }
}


.header > h2 {
  margin: 0;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.header > h4 {
  margin-top: 10px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(213, 213, 213, 0.768);
}

.form {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.form > p {
  text-align: right;
}

.form > p > a {
  color: #000;
  font-size: 14px;
}

.form-field {
  height: 46px;
  padding: 0 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  outline: 0;
  transition: .2s;
  margin-top: 20px;
}

.form-field:focus {
  border-color: #0f7ef1;
}

.form > button {
  padding: 12px 10px;
  border: 0;
  background: red; 
  border-radius: 3px;
  margin-top: 10px;
  color: #fff;
  letter-spacing: 1px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

button:hover {
    background: #000000;
} 

.animation {
  animation-name: move;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-delay: 2s;
}

.a1 {
  animation-delay: 2s;
}

.a2 {
  animation-delay: 2.1s;
}

.a3 {
  animation-delay: 2.2s;
}

.a4 {
  animation-delay: 2.3s;
}

.a5 {
  animation-delay: 2.4s;
}

.a6 {
  animation-delay: 2.5s;
}

@keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    padding: 20px 40px;
    width: 440px;
  }
}