@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');


:root {
  --primary-color: red;
  --secondary-color: #fed700;
  --primary-font: 'Montserrat', sans-serif;  
}

body {
  margin: 0;
  font-family: "League Gothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

/* HOME */

.video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 60%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.logoHeader {
  width: 15%;
}
.containerElements {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parrafoHome {
  font-family: var(--primary-font);
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 570px) {
  .parrafoHome {
    font-family: var(--primary-font);
    text-transform: uppercase;
    font-size: 1.3rem;
    text-align: center;
    color: #ffffff;
  }
}

/* Botón reservar */

.frame {
  width: 100%;
  margin: 10px auto;
  text-align: center;
}

.custom-btn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  background: black;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}
.btn-11 {
  overflow: hidden;
  transition: all 0.3s ease;
}
.btn-11:hover {
  background: red;
  color: black;
  border: none;
}
.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 4s ease-in-out infinite;
}
.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

/* Nosotros */

.nosotrosPadre {
  background-color: #fff;
  margin-top: 12rem;
}
.containerNosotros {
  padding: 1rem;
  font-family: var(--primary-font);
}
.containerNosotros p {
  margin-bottom: 1rem;
}
@media (max-width: 1360px) {
  .containerNosotros p {
    margin-bottom: 1rem;
    font-size: .9rem;
  }
}
@media (max-width: 570px) {
  .dique {
    
    padding: .1rem;
    width: 70vw;
  }
}
.titleNosotros {
  text-align: center;
  font-family: var(--primary-font);
  font-size: 2rem;
  padding: 4rem;
}
.dique {
  float: left;
  padding: 1rem;
}
span {
  color: var(--primary-color);
}

/* Galería de imágenes */

.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}
.polaroid {
  background: black;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);  
}
.polaroid > img{
  max-width: 100%;
  height: auto;
  filter: none;
}
.polaroid > img:hover {
  filter: grayscale(100%);  
}
.caption {
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--primary-font);
  color: red;
  line-height: 2em;
}
.item {
  width: 30%;
  display: inline-block;
  margin-top: 2rem;
}
@media (max-width: 699px) {
  .item {
    width: 90%;
    display: inline-block;
    margin-top: 2rem;
  }
  .wrapper {
    width: 100%;
    padding: 0 2rem;
    text-align: center;
  }
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}
.subtitleCabañas {
  font-family: var(--primary-font);
  font-size: 1.5rem;
  text-align: center;
}

/* Iconos servicios */

.iconos-valores {
  font-family: var(--primary-font);
  text-transform: uppercase;
  background-color: #e9e9e9;
  margin-top: 3rem;
}
.container-iconos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3rem;
}

@media (max-width: 699px) {
  .container-iconos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 3rem;
  }
  .btn-12 {
    margin-top: 5rem;
  }
}
.texto-primeroT {
  color: black;
  filter: drop-shadow(3px 5px 20px rgb(255, 255, 255));
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}
.imagenD {
  width: 25%;
  height: 55%;
}
.imagenC {
  width: 15%;
  height: 15%;
}
.imagenE {
  width: 15%;
  height: 15%;
}
.text-icons {
  font-size: 0.8rem;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
}
.iconos-valores h3 {
  text-align: center;
  margin-bottom: 2rem;
  padding: 3rem;
}
.btn-12 {
  margin-bottom: 5rem;
}
.btn-12:hover {
background-color: red;
}

/*  Contacto  */

.containerContacto {
  background-image: url("./assets/ruta.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin-top: -3rem;
}
.containerFormulario {
  display: flex;
}
@media (max-width: 570px) {
  .containerContacto {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .containerContacto h3 {
    font-size: 1.5rem !important;
  }
  .containerFormulario {
    display: flex;
    width:100%;
  }
}
.infoContacto {
  font-family: var(--primary-font);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
}
.infoContacto p {
  text-align: center;
  font-size: .9rem;
}
.infoContacto img {
  filter: invert(100%);
}
.infoContacto img:hover {
  filter: none;
}
.infoContacto a {
  color: whitesmoke;
}
.infoContacto a:hover {
  color: black;
}

.containerContacto h3 {
  text-align: center;
  margin-bottom: -1.5rem;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  padding: 1.5rem;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.containerContacto .sub {
  text-align: center;
  color: rgb(226, 226, 226);
  font-size: 1rem;
  font-family: var(--primary-font);
  text-transform: uppercase;
}
.mapsContacto {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5rem;
}

/* Footer */

footer {
  background-color: #000000;
  padding: 0.5rem;
  
}
a {
  text-decoration: none;
}
.footerContainer {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.logoWts:hover {
  animation: myAnim 1s ease 0s 1 normal forwards;
}
.logoIg:hover {
  animation: myAnim 1s ease 0s 1 normal forwards;
}
.logoFb:hover {
  animation: myAnim 1s ease 0s 1 normal forwards;
}
@keyframes myAnim {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(1);
	}

	100% {
		box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
		transform: scale(1.3);
	}
}
.containerFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prefooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.text-footer {
  text-align: center;
  color: rgb(156, 156, 156);
  font-size: .8rem;
}
.text-footer2 {
  text-align: center;
  color: rgb(110, 110, 110);
  opacity: 100%;
  font-size: .7rem;
}
.firma {
  text-transform: none;
  color: #00fe7f95;
  font-size: .7rem;
  text-decoration: none;
}
.firma:hover {
  color: #00fe7f;
}
#get-current-year {
  color: rgb(156, 156, 156);
}

/* Enviado */

.titleEnviado {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  color: black;
  padding: 1rem;
  margin-top: 7rem;
  font-size: 2.5rem;
  margin-left: 2rem;
  text-align: center;
  height: 50vh;
}
.btnEnviado {
    padding: 12px 10px;
    text-align: center;
    border: 0;
    border-radius: 3px;
    margin-top: 10px;
    background-color: var(--primary-color);
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-family: var(--primary-font);
    text-transform: uppercase;
    cursor: pointer;
}
.contenedorBtn {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 1.5rem;
  }  
}